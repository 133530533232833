import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Heading, TextMono, Text, Button } from "@atoms"
import { Link } from "gatsby"
import {
  HeroContainer,
  HeroBreadcrumbs,
  HomeCrumb,
  TextContentContainerOneCTA,
  TextContentContainerTwoCTA,
  Gradient,
  CTAContainer,
} from "./styles/HeroKnowledgeCenterListing.styled"
import OptimizedImage from "../../utils/optimizedImage"

const HeroKnowledgeCenterListing = ({ component }) => {
  // const [search, setSearch] = useState("");
  const { type, callsToAction, subheading, kicker, heading, headingSize } =
    component

  let ctaCount = callsToAction?.length

  return (
    <HeroContainer data-comp={HeroKnowledgeCenterListing.displayName}>
      <OptimizedImage
        image={component?.featuredImage?.gatsbyImageData}
        src={component.featuredImage?.file.url}
        alt=""
        loading="lazy"
        className="hero-image"
      />
      <HeroBreadcrumbs>
        <Link style={{ textDecoration: "none" }} href="/">
          <HomeCrumb
            id="resource-hub--home-crumb"
            style={{
              textDecoration: "none",
              color: "white",
              transition: "color 200ms ease",
            }}
          >
            Home
          </HomeCrumb>
        </Link>
        <img src="/icons/chevron-right-white.svg" alt="icon" />
        <div style={{ fontWeight: 700, cursor: "pointer" }}>{heading}</div>
      </HeroBreadcrumbs>
      {!ctaCount && (
        <TextContentContainerOneCTA>
          {kicker && (
            <TextMono style={{ color: "#69D1F0", paddingBottom: "12px" }}>
              {kicker}
            </TextMono>
          )}
          {heading && (
            <Heading
              headingSize={headingSize}
              style={{ color: "white", paddingBottom: "16px" }}
            >
              {heading}
            </Heading>
          )}
          {subheading?.raw && (
            <Text headingSize={headingSize} style={{ color: "white" }}>
              {documentToReactComponents(
                JSON.parse(component?.subheading?.raw)
              )}
            </Text>
          )}
          {/* <SearchArea value={search} placeholder="Search knowledge center" setSearch={setSearch}/> */}
        </TextContentContainerOneCTA>
      )}
      {ctaCount < 2 && (
        <TextContentContainerOneCTA>
          {kicker && (
            <TextMono style={{ color: "white", paddingBottom: "12px" }}>
              {kicker}
            </TextMono>
          )}
          {heading && (
            <Heading
              headingSize={headingSize}
              style={{ color: "white", paddingBottom: "16px" }}
            >
              {heading}
            </Heading>
          )}
          {subheading?.raw && (
            <Text headingSize={headingSize} style={{ color: "white" }}>
              {documentToReactComponents(
                JSON.parse(component?.subheading?.raw)
              )}
            </Text>
          )}
          {/* <SearchArea value={search} placeholder="Search knowledge center" setSearch={setSearch}/> */}
          {callsToAction && callsToAction[0]?.link && (
            <Button
              style={{ marginTop: "30px" }}
              ctaVariant={"secondary"}
              ctaLink={callsToAction[0]?.link}
              {...callsToAction[0]}
            >
              {callsToAction[0]?.label}
            </Button>
          )}
        </TextContentContainerOneCTA>
      )}
      {ctaCount > 1 && (
        <TextContentContainerTwoCTA>
          {kicker && (
            <TextMono style={{ color: "white", paddingBottom: "12px" }}>
              {kicker}
            </TextMono>
          )}
          {heading && (
            <Heading
              headingSize={headingSize}
              style={{ color: "white", paddingBottom: "16px" }}
            >
              {heading}
            </Heading>
          )}
          {subheading?.raw && (
            <Text pb={6} headingSize={headingSize} style={{ color: "white" }}>
              {documentToReactComponents(
                JSON.parse(component?.subheading?.raw)
              )}
            </Text>
          )}
          {/* <SearchArea value={search} placeholder="Search knowledge center" setSearch={setSearch}/> */}
          <CTAContainer style={{ display: "flex", margin: "0 auto" }}>
            {callsToAction[1]?.link &&
              component?.callsToAction.map((cta, index) => {
                return (
                  <div>
                    <div
                      className="button-wrapper"
                      style={{
                        margin:
                          index === 0 ? "0px 12px 0px 0px" : "0px 0px 0px 12px",
                      }}
                    >
                      <Button ctaLink={cta?.link} ctaVariant={cta?.variant} {...cta}>
                        {cta.label}
                      </Button>
                    </div>
                  </div>
                )
              })}
          </CTAContainer>
        </TextContentContainerTwoCTA>
      )}
      <Gradient type={type} />
    </HeroContainer>
  )
}

// const SearchArea = ({ value, placeholder, setSearch }) => {
//   return (
//     <form>
//       <input
//         type="text"
//         value={value}
//         onChange={(e)=> setSearch(e.target.value)}
//         placeholder={placeholder}
//       />
//     </form>
//   )
// }
export default HeroKnowledgeCenterListing
HeroKnowledgeCenterListing.displayName = "HeroKnowledgeCenterListing"
